import store from 'shared/store'
import { VOLUME_PRICING_MODAL_CONTAINER_ID } from 'views/components/organisms/VolumePricingModal/names'
import { getVolumePricingFromBestOffer } from 'views/components/organisms/VolumePricingModal/utils/volumePricingAccessor'
import loadCatalogs from 'views/containers/browser/utils/loadCatalogs'
import buildClientAPI from 'views/providers/clientAPI'

import { OPEN_VOLUME_PRICING_MODAL } from './clientsideHook'

const mountVolumePricingModalContainer = async () => {
  const searchResultsLinks = document.querySelectorAll(
    `[data-clientside-hook*="${OPEN_VOLUME_PRICING_MODAL}"]`
  )
  const modal = document.getElementById(VOLUME_PRICING_MODAL_CONTAINER_ID)
  const { pimProductService } = buildClientAPI()

  if (!modal) {
    return
  }

  const {
    publicConfig: { locale },
  } = store.getPublicRuntimeConfig()

  const i18n = await loadCatalogs(locale)

  const volumePricingModalContainer = await import(
    './VolumePricingModalContainerWithHydration'
  )

  const upids = Array.from(searchResultsLinks, link =>
    link.getAttribute('data-product-identifier')
  )

  pimProductService.fetchMultiplePimProducts(upids).then(response => {
    const products = response.data.items
    searchResultsLinks.forEach(link => {
      const product = products.find(
        product => product.upid === link.getAttribute('data-product-identifier')
      )
      const volumeBasedPrices = getVolumePricingFromBestOffer(product)
      link.addEventListener('click', () =>
        volumePricingModalContainer.default({
          searchResultUpid: product.upid,
          volumeBasedPrices,
          container: modal,
          i18n,
        })
      )
    })
  })
}

export default mountVolumePricingModalContainer
