import type {
  OfferV1,
  PimProductV1,
  VolumeBasedPriceV1,
} from '@redteclab/api/clients/bully'

import { type SearchHitProductVariant } from 'types/adServerTypes'

export interface RangesData {
  id: number
  price: number
  range: string
}

export const getEntryHasVolumePricing = (
  entry: SearchHitProductVariant
): boolean => {
  return entry?.best_offer?.volume_based_prices?.length > 1 || false
}

// bestOffer is the one that is displayed in SERP
const getBestOfferFromProduct = (
  product: PimProductV1
): OfferV1 | undefined => {
  return product.offers?.find(offer => offer?.offerFlags?.includes('bestOffer'))
}

export const getVolumePricingFromBestOffer = (
  product: PimProductV1
): VolumeBasedPriceV1[] => {
  return getBestOfferFromProduct(product)?.volumeBasedPrices ?? []
}

export const useGetDiscountRanges = (
  volumePrices: VolumeBasedPriceV1[]
): RangesData[] | undefined => {
  const sortedVolumePrices = [...volumePrices].sort(
    (firstPrice, secondPrice) =>
      (firstPrice.quantityThreshold ?? 0) - (secondPrice.quantityThreshold ?? 0)
  )

  return sortedVolumePrices.map((discount, index) => {
    const rangeStart = discount.quantityThreshold
    const nextDiscount =
      index + 1 < sortedVolumePrices.length
        ? sortedVolumePrices[index + 1]
        : undefined
    const rangeEnd = nextDiscount?.quantityThreshold
      ? nextDiscount.quantityThreshold - 1
      : undefined

    const range = rangeEnd ? `${rangeStart}-${rangeEnd}` : `${rangeStart}`

    return {
      id: index,
      price: discount.unitOriginPrice ?? 0,
      range,
    }
  })
}
